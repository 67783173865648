<template>
  <v-menu
    :close-on-content-click="false"
    :nudge-width="200"
    max-width="600px"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <base-material-card
        icon="$device-white"
        color="secondary"
        title="Unidades"
      >
        <template v-slot:after-heading>
          <div>
            <span class="grey--text">Total: </span>
            <span>{{ data.total }}</span>
          </div>
          <div>
            <span class="grey--text">Activos: </span>
            <span>{{ data.active }}</span>
          </div>
          <div>
            <span class="grey--text">Inactivos: </span>
            <span>{{ data.inactive }}</span>
          </div>
        </template>
        <template v-slot:actions>
          <div
            v-bind="attrs"
            v-on="on"
          >Unidades</div>
        </template>
      </base-material-card>
    </template>
    <v-card>
      <v-tabs
        v-model="tab"
        align-with-title
      >
        <v-tabs-slider color="yellow"></v-tabs-slider>

        <v-tab
          v-for="item in ['Activos', 'Inactivos']"
          :key="item"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-data-table
              :headers="table.headers"
              :items="items.filter((a) => a.status !== 'down')"
              :items-per-page="10"
            >
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-data-table
              :headers="table.headers"
              :items="items.filter((a) => a.status == 'down')"
              :items-per-page="10"
            >
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'DevicesCard',
  props: {
    items: Array,
    data: Object
  },
  data() {
    return {
      tab: null,
      table: {
        headers: [
          {
            sortable: false,
            text: 'Nombre',
            value: 'name',
          },
          {
            sortable: false,
            text: 'Direccion',
            value: 'address',
          }
        ],
      },
    }
  }
}
</script>
